<template>
  <div>
    <action-improvement-problem-add-new
      :is-add-new-action-improvement-problem-sidebar-active.sync="isAddNewActionImprovementProblemSidebarActive"
      @refresh-data="() => { refActionImprovementProblemList.refreshData() }"
    />
    <list
      ref="refActionImprovementProblemList"
      sort-by="AccionMejoraProblemaId"
      :refetch-records="fetchActionImprovementProblems"
      refetch-records-name="accionMejoraProblemas"
      :actions="actions"
      key-field="AccionMejoraProblemaId"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :delete-method="deleteActionImprovementProblem"
      :filters.sync="filters"
    />
  </div>
</template>
<script>
import { ref } from '@vue/composition-api'
import generalData from '@/services/generalData.service'
import i18n from '@/libs/i18n'
import List from '@/components/List.vue'
import ActionImprovementProblemAddNew from './ActionImprovementProblemAddNew.vue'

export default {
  components: {
    ActionImprovementProblemAddNew,
    List,

  },
  setup() {
    /* Refs */
    const refActionImprovementProblemList = ref(null)
    const isAddNewActionImprovementProblemSidebarActive = ref(false)
    /* Services */
    const { fetchActionImprovementProblems, fetchActionImprovementProblem, deleteActionImprovementProblem } = generalData()
    const tableColumns = [
      {
        label: 'Clave',
        key: 'clave',
      },
      {
        label: 'Nombre',
        key: 'nombre',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const tableActions = ref([
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'GeneralDatos',
        routeName: 'apps-action-improvement-problem-edit',
        params: ['accionMejoraProblemaId'],
        icon: 'CodepenIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'GeneralDatos',
        params: ['accionMejoraProblemaId'],
        // disabledCondition: item => item.estadoCivilId === 3,
        icon: 'TrashIcon',
      },
    ])
    const actions = ref([
      {
        label: 'Nueva accion mejora',
        aclAction: 'create',
        click: () => { isAddNewActionImprovementProblemSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
    ])
    const filters = ref([])
    // const refetchWorkAreas = (ctx, callback) => {
    //   fetchWorkAreas({
    //     searchQuery: searchQuery.value,
    //     limit: perPage.value,
    //     offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
    //     sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
    //     estado: status.value,
    //   }, data => {
    //     records.value = data.areasTrabajo
    //     totalWorkArea.value = data.total
    //     callback(records.value)
    //   })
    // }
    /* UI */
    // const dataMeta = computed(() => {
    //   const localItemsCount = refWorkAreaTable.value ? refWorkAreaTable.value.localItems.length : 0
    //   return {
    //     from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
    //     to: perPage.value * (currentPage.value - 1) + localItemsCount,
    //     of: totalWorkArea.value,
    //   }
    // })
    // const refreshData = () => {
    //   refWorkAreaTable.value.refresh()
    // }
    // watch([currentPage, perPage, status, searchQuery], () => {
    //   refreshData()
    // })
    return {
      // Ref
      refActionImprovementProblemList,
      tableColumns,
      isAddNewActionImprovementProblemSidebarActive,
      tableActions,
      actions,
      filters,
      // API Calls
      fetchActionImprovementProblems,
      fetchActionImprovementProblem,
      deleteActionImprovementProblem,
      // Params
      // searchQuery,
      // sortBy,
      // refetchWorkAreas,
      // isSortDirDesc,
      // Data
      // totalWorkArea,
      // records,
      // UI
      // dataMeta,
      // Pagination
      // perPageOptions,
      // perPage,
      // currentPage,
      // status,
      // // Event
      // refreshData,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
